import React from "react";
// import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

type Proos = {
  title?: string;
  description?: string;
  ogType?: string;
  url: string;
  image: string;
  siteName?: string;
};

const OGP = ({ title, description, ogType, url, image, siteName }: Proos) => {
  const { site } = useStaticQuery(query);
  return (
    <>
      <meta property="og:title" content={title || site.siteMetadata.title} />
      <meta property="og:type" content={ogType || "website"} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={siteName || "まるっと名言"} />
      <meta
        property="og:description"
        content={description || site.siteMetadata.description}
      />
      <meta name="twitter:card" content="summary_large_image" />
    </>
  );
};

export default OGP;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
