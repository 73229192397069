import { graphql, PageProps, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
} from "@material-ui/core";

import Layout from "../layouts/layout";
import SEO from "../components/seo";
import OGP from "../components/ogp";
import { Link } from "../components/link";
import SayingCard from "../components/sayingCard";
import SayingList from "../components/sayingList";
import Breadcrumbs from "@mui/material/Breadcrumbs";

export default function SayingPage({ data }) {
  const { mysqlSaying, allMysqlTagPerson, allMysqlSaying } = data;
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const col = isLg ? 3 : isSm ? 2 : 1;

  useEffect(() => {
    const watchedSayings =
      localStorage.getItem("watchedSayings") == null
        ? []
        : JSON.parse(localStorage.getItem("watchedSayings"));
    const concated = watchedSayings.concat([mysqlSaying]);
    const uniqResult = concated
      .slice(-12)
      .reverse()
      .filter((e, index, self) => {
        return self.findIndex((el) => el.mysqlId === e.mysqlId) === index;
      })
      .reverse();
    const json = JSON.stringify(uniqResult, undefined, 1);
    localStorage.setItem("watchedSayings", json);
  }, []);

  return (
    <Layout>
      <>
        <Box style={{ padding: "4px 0", height: "42px", overflow: "scroll" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">トップ</Link>
            <Link to={`/persons/${mysqlSaying.person.mysqlId}`}>
              {`${mysqlSaying.person.name}の名言集`}
            </Link>
            <Typography>{mysqlSaying.content}</Typography>
          </Breadcrumbs>
        </Box>

        <SEO
          canonical={`https://marutto-meigen.com/persons/${mysqlSaying.person.mysqlId}`}
          title={`${mysqlSaying.person.name} - ${mysqlSaying.content}`}
          description={mysqlSaying.person.description}
        />
        <Typography
          component="h1"
          style={{ margin: "12px 0", fontWeight: "bold" }}
        >
          {mysqlSaying.person.affiliation == null ? (
            <>{mysqlSaying.person.name}の名言</>
          ) : (
            <>
              {mysqlSaying.person.name}の名言 -{" "}
              <Link
                to={`/affiliations/${mysqlSaying.person.affiliation.mysqlId}/page/1`}
              >
                {mysqlSaying.person.affiliation.name}
              </Link>
            </>
          )}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <SayingCard saying={mysqlSaying} />
            {mysqlSaying.content.length <= 80 &&
              mysqlSaying.mysqlId <= 27629 && (
                <>
                  <Box height={"18px"} />
                  <Box style={{ display: "flex", backgroundColor: "black" }}>
                    <Box>
                      <img
                        src={`https://marutto-meigen.com/images/sayings/${mysqlSaying.mysqlId}/001.jpg`}
                        alt={`【背景1】${mysqlSaying.content} - ${mysqlSaying.person.name}`}
                        width="100%"
                      />
                    </Box>

                    <Box>
                      <img
                        src={`https://marutto-meigen.com/images/sayings/${mysqlSaying.mysqlId}/002.jpg`}
                        alt={`【背景2】${mysqlSaying.content} - ${mysqlSaying.person.name}`}
                        width="100%"
                      />
                    </Box>

                    <Box>
                      <img
                        src={`https://marutto-meigen.com/images/sayings/${mysqlSaying.mysqlId}/003.jpg`}
                        alt={`【背景3】${mysqlSaying.content} - ${mysqlSaying.person.name}`}
                        width="100%"
                      />
                    </Box>

                    <Box>
                      <img
                        src={`https://marutto-meigen.com/images/sayings/${mysqlSaying.mysqlId}/004.jpg`}
                        alt={`【背景4】${mysqlSaying.content} - ${mysqlSaying.person.name}`}
                        width="100%"
                      />
                    </Box>
                  </Box>
                </>
              )}
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Box>
              <Typography component="h2" style={{ fontWeight: "bold" }}>
                関連するトピック
              </Typography>
              {allMysqlTagPerson.nodes.map((node) => (
                <Typography component="span" style={{ marginRight: "4px" }}>
                  <Link to={`/tags/${node.tag.mysqlId}/page/1`}>
                    {node.tag.name}
                  </Link>
                </Typography>
              ))}
              <Box style={{ height: "12px" }} />
              {mysqlSaying.person.affiliation && (
                <>
                  <Typography component="h2" style={{ fontWeight: "bold" }}>
                    関連する人物
                  </Typography>
                  <Box>
                    {mysqlSaying.person.affiliation.people.map((person) => {
                      if (person.mysqlId === mysqlSaying.person.mysqlId) {
                        return;
                      }
                      return (
                        <Typography
                          component="span"
                          style={{ marginRight: "4px" }}
                        >
                          <Link to={`/persons/${person.mysqlId}`}>
                            {person.name}
                          </Link>
                        </Typography>
                      );
                    })}
                  </Box>
                </>
              )}
              <Box style={{ height: "12px" }} />
              <Typography component="h2" style={{ fontWeight: "bold" }}>
                {mysqlSaying.person.name}について
              </Typography>
              {mysqlSaying.person.description !== "" ? (
                <>
                  <Box style={{ height: "4px" }} />
                  <Typography>{mysqlSaying.person.description}</Typography>
                </>
              ) : (
                <>
                  <a
                    target={"_blank"}
                    href={`https://ja.wikipedia.org/wiki/${mysqlSaying.person.name}`}
                  >
                    詳しく
                  </a>
                </>
              )}
            </Box>
            <Box style={{ height: "12px" }} />
          </Grid>
        </Grid>
        {allMysqlSaying != undefined && allMysqlSaying.nodes.length != 0 && (
          <>
            <Box
              style={{
                margin: "28px auto",
                marginBottom: "42px",
                position: "relative",
                maxWidth: "720px",
              }}
            >
              <Typography
                component="h2"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  backgroundColor: "white",
                  width: "220px",
                  margin: "0 auto",
                  position: "relative",
                  top: "12px",
                }}
              >
                🔍関連する名言を探す
              </Typography>
              <Box height={0} style={{ borderTop: "1px solid #ccc" }} />
            </Box>
            <SayingList
              sayings={allMysqlSaying.nodes}
              col={col}
              randomThumbnail={true}
            />
          </>
        )}
      </>
    </Layout>
  );
}

export const query = graphql`
  query SayingTemplate($id: Int!, $personId: Int!, $simSayingIds: [Int]) {
    mysqlSaying(mysqlId: { eq: $id }) {
      mysqlId
      content
      person {
        mysqlId
        name
        description
        affiliation {
          mysqlId
          name
          people {
            mysqlId
            name
          }
        }
      }
    }
    allMysqlTagPerson(filter: { person_id: { eq: $personId } }) {
      nodes {
        tag {
          mysqlId
          name
        }
      }
    }
    allMysqlSaying(filter: { mysqlId: { in: $simSayingIds } }) {
      nodes {
        mysqlId
        content
        person {
          mysqlId
          name
          description
          affiliation {
            mysqlId
            name
            people {
              mysqlId
              name
            }
          }
        }
      }
    }
  }
`;

export function Head({ data }) {
  const { mysqlSaying } = data;
  const jsonLd = `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "トップ",
        "item": "https://marutto-meogen.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "${mysqlSaying.person.name}の名言集",
        "item": "https://marutto-meigen.com/persons/${mysqlSaying.person.mysqlId}"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "${mysqlSaying.content}",
        "item": "https://marutto-meigen.com/sayings/${mysqlSaying.mysqlId}"
      }
    ]
  }`;
  return (
    <>
      <OGP
        title={`${mysqlSaying.person.name} - ${mysqlSaying.content}`}
        image={`https://marutto-meigen.com/images/sayings/${mysqlSaying.mysqlId}/002.jpg`}
        url={`https://marutto-meigen.com/sayings/${mysqlSaying.mysqlId}`}
      />
      <script type="application/ld+json">{jsonLd}</script>
    </>
  );
}
